import DatePicker from 'react-date-picker'
import { XIcon } from 'lucide-react'

import 'react-date-picker/dist/DatePicker.css'
import 'react-calendar/dist/Calendar.css'

interface Props {
  value?: Date

  onChange?: (date?: Date) => void
}

export const DateInput = ({ value, onChange }: Props) => {
  return (
    <DatePicker
      value={value}
      format="y-MM-dd"
      yearPlaceholder="YYYY"
      monthPlaceholder="MM"
      dayPlaceholder="DD"
      disableCalendar
      clearIcon={value ? <XIcon size={14} /> : null}
      className={'!text-sm [&_*]:!border-none w-full [&_button]:p-0'}
      onChange={(date) => onChange?.(date as Date)}
      maxDate={new Date('9999-12-31')}
    />
  )
}
