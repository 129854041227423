'use client'

import * as React from 'react'
import { Calendar as CalendarIcon, XIcon } from 'lucide-react'
import { DateRange } from 'react-day-picker'
import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/Button'
import { Calendar } from '@/components/ui/Calendar'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/Popover'
import { dateToYMD } from '@core/util/date'
import { Label } from '@/components/ui/Label'
import { DateInput } from './DateInput'

interface Props {
  value?: DateRange
  disabled?: boolean
  placeholder?: string
  classes?: {
    root?: string
    trigger?: string
    content?: string
  }
  defaultEndDateToTodayIfEmpty?: boolean

  onChange?: (dateRange?: DateRange) => void
}

export function DateRangePicker({
  placeholder,
  classes,
  value,
  disabled,
  onChange,
  defaultEndDateToTodayIfEmpty,
}: Props) {
  const [displayMonth, setDisplayMonth] = React.useState(value?.from)

  const handleDateInputChange = (date: Date | undefined, isTo: boolean) => {
    const newRange = isTo ? { from: value?.from, to: date } : { from: date, to: value?.to }
    onChange?.(newRange as DateRange)
    setDisplayMonth(date)
  }

  const handleClearRangeClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
    onChange?.(undefined)
  }

  const onDefaultEndDateToTodayIfEmpty = () => {
    const shouldAutoFillEndDate = value?.from && !value?.to
    if (!shouldAutoFillEndDate) return

    const today = new Date()
    const newRange = { from: value.from, to: today }
    onChange?.(newRange)
  }

  const onOpenChange = (isOpen: boolean) => {
    setDisplayMonth(value?.from)
    !isOpen && defaultEndDateToTodayIfEmpty && onDefaultEndDateToTodayIfEmpty()
  }

  const hasRange = value?.from || value?.to

  return (
    <div className={cn('grid gap-2', classes?.root)}>
      <Popover onOpenChange={onOpenChange}>
        <PopoverTrigger asChild>
          <div className={cn('relative overflow-hidden', { 'pointer-events-none': disabled })}>
            {hasRange && (
              <Button
                variant="ghost"
                className="p-0 opacity-50 h-fit hover:opacity-100 absolute right-1 top-3"
                onClick={handleClearRangeClick}
              >
                <XIcon size={14} />
              </Button>
            )}

            <Button
              id="date"
              type="button"
              variant={'outline'}
              className={cn(
                'w-[300px] justify-start text-left font-normal',
                !hasRange && 'text-muted-foreground',
                classes?.trigger,
              )}
              disabled={disabled}
            >
              <CalendarIcon className={cn('mr-2 h-4 w-4 shrink-0', !hasRange && 'text-gray-400')} />
              <span className="overflow-hidden text-ellipsis">
                {hasRange ? (
                  <>
                    {value.from ? dateToYMD(value.from) : '_'} - {value.to ? dateToYMD(value.to) : '_'}
                  </>
                ) : (
                  <span className={cn('text-muted-foreground', !hasRange && 'text-slate-400')}>
                    {placeholder || 'Pick a date'}
                  </span>
                )}
              </span>
            </Button>
          </div>
        </PopoverTrigger>

        <PopoverContent className={cn('w-auto p-0', classes?.content)} align="start">
          <div className="p-2 grid grid-cols-2 gap-2 w-full">
            <div className="flex-1">
              <Label>From</Label>
              <div className="relative border border-slate-200 pr-1 pl-9 rounded-md h-9 flex items-center flex-1">
                <DateInput value={value?.from} onChange={(date) => handleDateInputChange(date, false)} />
                <CalendarIcon className="absolute left-3 top-1/2 h-[18px] w-[18px] -translate-y-1/2 text-gray-500 peer-focus:text-gray-900" />
              </div>
            </div>

            <div>
              <Label>To</Label>
              <div className="relative border border-slate-200 pr-1 pl-9 rounded-md h-9 flex items-center flex-1">
                <DateInput value={value?.to} onChange={(date) => handleDateInputChange(date, true)} />
                <CalendarIcon className="absolute left-3 top-1/2 h-[18px] w-[18px] -translate-y-1/2 text-gray-500 peer-focus:text-gray-900" />
              </div>
            </div>
          </div>

          <Calendar
            initialFocus
            disabled={disabled}
            mode="range"
            month={displayMonth}
            defaultMonth={displayMonth}
            selected={value}
            onSelect={onChange}
            numberOfMonths={2}
            onMonthChange={(month) => setDisplayMonth(month)}
          />
        </PopoverContent>
      </Popover>
    </div>
  )
}
